<template>
  <div
    v-if="type === 'password'"
    class="form-item"
  >
    <div class="form-item__header">
      <label :for="name">{{ label }}</label>
      <button
        v-if="passwordIsVisible"
        @click="togglePassword"
      >
        <NuxtImg
          src="/img/icon-hide.svg"
          width="24px"
        />
        {{ $t('button.hide') }}
      </button>
      <button
        v-else
        @click="togglePassword"
      >
        <NuxtImg
          src="/img/icon-show.svg"
          width="24px"
        />
        {{ $t('button.show') }}
      </button>
    </div>
    <input
      :id="name"
      :type="passwordFieldType"
      :value="modelValue"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      autocomplete="on"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    />
    <span>{{ error }}</span>
  </div>
  <div
    v-else
    class="form-item"
  >
    <label :for="name">{{ label }}</label>
    <textarea
      v-if="textarea"
      :id="name"
      autocomplete="on"
      :name="name"
      cols="10"
      rows="6"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    ></textarea>
    <input
      v-else
      :id="name"
      autocomplete="on"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :name="name"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    />
    <span>{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
defineProps<{
	modelValue: string;
	name: string;
	type?: string;
	label?: string;
	placeholder?: string;
	textarea?: boolean;
	error?: string;
  disabled?: boolean;
}>();

defineEmits(['update:modelValue']);

const passwordIsVisible = ref(false);

const passwordFieldType = computed(() => (passwordIsVisible.value ? 'text' : 'password'));

const togglePassword = () => {
  passwordIsVisible.value = !passwordIsVisible.value;
};
</script>

<style scoped></style>
